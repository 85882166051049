import React from "react";
import styled from "styled-components";

const Background = styled.div`
  background: url(https://i.imgur.com/PBJU7bd.jpg) center center / cover no-repeat;
  position: absolute;
  width: 100%;
  height: 320px;
  content: "";
`;

const SearchPanel = styled.div`
  padding: 40px 48px 65px;
  width: 100%;
  position: relative;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px 3px;
  border-radius: 16px;
  background-color: rgb(248, 247, 249);
`;

const TabPanel = styled.ul`
  padding-left: 64px;
  padding-right: 64px;
  left: auto;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 6px 2px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  z-index: 998;
  position: relative;
  max-width: 700px;
  margin: 0 auto;
`;

const Submit = styled.button`
  position: relative;
  border: none;
  padding: 12px;
  border-radius: 8px;
  user-select: none;
  background-color: rgb(83, 146, 249);
  color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 1px;
  transition: all 0.15s ease-in-out 0s;
  width: 490px;
  height: 64px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
`

export {
    Background, SearchPanel, TabPanel, Submit
}
