import React from 'react';
import './style.scss';

export default function Room(props: any) {

    const onSelect = () => {
        props.onSelect();
    }

    return (
        <div className="MasterRoom" onClick={() => onSelect()}>
            <div className="MasterRoom-header">
                <div className="MasterRoom-headerLeft">
                    <a className="MasterRoom-headerTitle">
                        <div className="MasterRoom-headerTitle--text">
                            <div className="">
                                <span>Phòng Giường Đôi Nhỏ (Small Single Room without Window)</span>
                            </div>
                        </div>
                    </a></div>
                <div className="MasterRoom-headerRight"></div>
            </div>
            <div className="MasterRoom-table">
                <div className="MasterRoom-row">
                    <div className="MasterRoom-info">
                        <div className="MasterRoom-infoHeader">Loại phòng</div>
                        <div className="MasterRoom-infoPhotoContainer">
                            <div className="MasterRoom-roomImagePreview MasterRoom-roomImagePreview--hide"/>
                            <div className="MasterRoom-infoPhoto">
                                <img
                                    src="//pix6.agoda.net/hotelImages/13752940/-1/682945f2f6c3991768e001edc472a217.jpg?s=208x117&amp;ar=16x9"/>
                            </div>
                        </div>
                        <div>
                            <a className="MasterRoom-infoSeePhotos">Xem ảnh và chi tiết</a>
                            <ul className="MasterRoom-amenities">
                                <div className="MasterRoom__animatedAmenity MasterRoom__animatedAmenity--toggle">
                                    <li className="MasterRoom-amenitiesItem">
                                        <div>
                                            <i className="ficon ficon-wifi MasterRoom-amenitiesIcon"/>
                                            <div className="MasterRoom-amenitiesTitle">
                                                <div className="">Wifi miễn phí</div>
                                            </div>
                                        </div>
                                    </li>
                                </div>
                                <li className="MasterRoom-amenitiesItem">
                                    <div><i className="ficon ficon-bed MasterRoom-amenitiesIcon"/>
                                        <div className="MasterRoom-amenitiesTitle">
                                            <div className="">1 giường đôi lớn</div>
                                        </div>
                                    </div>
                                </li>
                                <li className="MasterRoom-amenitiesItem">
                                    <div><i className="ficon ficon-sqm MasterRoom-amenitiesIcon"/>
                                        <div className="MasterRoom-amenitiesTitle">
                                            <div className="">Diện tích phòng: 13 m²</div>
                                        </div>
                                    </div>
                                </li>
                                <li className="MasterRoom-amenitiesItem RoomAmenities__Showmore">
                                    <div><i className="ficon ficon-plus-with-circle MasterRoom-amenitiesIcon"/>
                                        <div className="MasterRoom-amenitiesTitle">
                                            <div className="">Các tiện ích khác</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="MasterRoom-roomsList">
                        <div className="ChildRoomsList ChildRoomsList--flex">
                            <div className="ChildRoomsList-header">
                                <div className="ChildRoomsList-headerCell ChildRoomsList-headerCellIncluded">Giá này đã
                                    gồm
                                </div>
                                <div className="ChildRoomsList-headerCell ChildRoomsList-headerCellCapacity">Sức chứa
                                </div>
                                <div className="ChildRoomsList-headerCell ChildRoomsList-headerCellPrice">Giá
                                    phòng/đêm
                                </div>
                                <div className="ChildRoomsList-headerCell ChildRoomsList-headerCellRooms">SL</div>
                                <div className="ChildRoomsList-headerCell ChildRoomsList-headerCellBook">Đặt nhiều
                                    nhất
                                </div>
                            </div>
                            <div className="ChildRoomsList-room">
                                <div className="ChildRoomsList-room-contents">
                                    <div className="ChildRoomsList-roomCell ChildRoomsList-roomCell-featureBuckets">
                                        <div className="ChildRoomsList-room-featurebuckets">
                                            <div
                                                className="ChildRoomsList-room-featurebucket ChildRoomsList-room-featurebucket-Benefits">
                                                <span className="ChildRoomsList-room-bucketspan">Lợi ích</span>
                                                <div>
                                                    <div
                                                        className="ChildRoomsList-roomFeature ChildRoomsList-roomFeature--green"
                                                        data-selenium="ChildRoomList-roomFeature"
                                                        data-room-feature-type="0">
                                                        <div>
                                                            <i className="RoomFeature__Icon ficon ficon-14 ficon-noti-check-mark-sharp"/><span
                                                            className="RoomFeature__Title RoomFeature__Title--plain"><span
                                                            className="ChildRoomsList-roomFeature-TitleWrapper">Wifi miễn phí</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div
                                                        className="ChildRoomsList-roomFeature ChildRoomsList-roomFeature--withHover ChildRoomsList-roomFeature--green"
                                                        data-selenium="ChildRoomList-roomFeature"
                                                        data-room-feature-type="5">
                                                        <div><i
                                                            className="RoomFeature__Icon ficon ficon-14 ficon-noti-check-mark-sharp"/><span
                                                            className="RoomFeature__Title RoomFeature__Title--plain"><span
                                                            className="ChildRoomsList-roomFeature-TitleWrapper">Giá rẻ (không hoàn tiền)<i
                                                            className="ficon ficon-10 ficon-hover-details"/></span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-ppapi="max-occupancy"
                                         className="ChildRoomsList-roomCell ChildRoomsList-roomCell-capacity"
                                         data-selenium="ChildRoomsList-roomCell">
                                        <div className="ChildRoomsList-capacity"
                                             data-selenium="ChildRoomsList-capacity"><i
                                            className="ficon ficon-12 ficon-hover-details ChildRoomsList-capacityTooltipIcon"></i><span
                                            className="Capacity-iconGroup"><i
                                            className="ficon ficon-adults-one ficon-24 ChildRoomsList-capacityIcon"
                                            data-ppapi="occupancyIcon"></i><i
                                            className="ficon ficon-adults-one ficon-24 ChildRoomsList-capacityIcon"
                                            data-ppapi="occupancyIcon"></i></span></div>
                                    </div>
                                    <div className="ChildRoomsList-roomCell ChildRoomsList-roomCell-price relativeCell"
                                         data-selenium="ChildRoomsList-roomCell">
                                        <div className="ChildRoom__PriceContainer">
                                            <div className="PriceContainer-Top">
                                                <div className="PriceDisplay PriceDisplay--noPointer"
                                                     data-element-name="price-display">
                                                    <div className="PriceContainer">
                                                        <div className="finalPrice swap" data-selenium="PriceDisplay"
                                                             data-element-name="final-price"><span className="pd-price"><strong
                                                            data-ppapi="room-price">319.190</strong></span><span
                                                            className="pd-currency"
                                                            data-ppapi="room-price-currency">₫</span></div>
                                                        <div className="PriceDisplay__TaxMessage">Giá mỗi đêm rẻ nhất
                                                            từ
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="PriceContainer-Bottom"></div>
                                        </div>
                                    </div>
                                    <div className="ChildRoomsList-roomCell ChildRoomsList-roomCell-selector"
                                         data-selenium="ChildRoomsList-roomCell">
                                        <div className="ChildRoomsList-roomDropdownWrapper">
                                            <div>
                                                <div className="ChildRoomsList-packageRoomCountPanel"><span
                                                    className="ChildRoomsList-packageRoomCount">1</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ChildRoomsList-roomCell ChildRoomsList-roomCell-book"
                                         data-selenium="ChildRoomsList-roomCell">
                                        <div className="ChildRoomsList-room-book">
                                            <div className="ChildRoomsList-bookButton">
                                                <div>
                                                    <button type="button" className="ChildRoomsList-bookButtonInput">Đặt
                                                        ngay
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ChildRoom__BookingColumnBottom"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
