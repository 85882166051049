import {Swiper, SwiperSlide} from 'swiper/react';
import React from "react";
import 'swiper/swiper.scss';

export default function ListCarousel(props: any) {

    const renderItem = (item: any, index: number) => {
        return <SwiperSlide key={index.toString()}>{props.renderItem(item)}</SwiperSlide>
    }

    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={7}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            {
                props.data.map((item: any, index: number) => renderItem(item, index))
            }
        </Swiper>
    )
}
