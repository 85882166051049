import React from "react";

export default function Breadcrumb(props: any) {
    return (<div>
        <div className="property-breadcrumb-container container-trippyasia">
            <ul className="BreadCrumb-hotel">
                <li className="BreadCrumb-hotel_item">
                    <div>
                        <a className="breadcrumb-link" href="">
                            <div className="breadcrumb-regionName" data-selenium="breadcrumb-region-name">Trang chủ
                            </div>
                        </a>
                    </div>
                </li>
                <li className="BreadCrumb-hotel_item">
                    <div className="breadcrumb-connector" data-selenium="breadcrumb-connector">&gt;</div>
                </li>


                <li className="BreadCrumb-hotel_item" data-element-name="breadcrumbs-item">
                    <div>
                        <h1 className="breadcrumb-regionName breadcrumbRegionName__h1">
                            Đặt phòng Cozrum Homes Charming Corner
                        </h1>
                    </div>
                </li>
            </ul>

            <div id="hotel-samecity-container" className="hotel-samecity-container">
                <div className="HotelsSameCity-container">
                    <a className="same-city-link" href="">Xem tất cả <strong>10.146</strong> khách sạn tại Hồ Chí Minh.</a>
                </div>
            </div>
        </div>
    </div>)
}
