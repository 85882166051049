import React, {useState} from "react";
import { Tabs } from 'antd';
import TopSection from "../../constants/TopSection";
import BlockSection from "../../constants/BlockSection";
import ListCarousel from "../../constants/ListCarousel";
import ImageCircle from "../../components/ImageCircle";
import ImageSquare from "../../components/ImageSquare";
import ListGrid from "../../constants/ListGird";
import ListProduct from "../../constants/ListProduct";
import Product from "../../components/Product";

const { TabPane } = Tabs;
export default function HomePage(props: any) {
    const [citiesFeature, setCitiesFeature] = useState([
        {image: 'https://pix6.agoda.net/geo/city/13170/1_13170_02.jpg?s=345x345&ar=1x1', title: 'Hồ Chí Minh', desc: '930 chỗ ở', url: ''},
        {image: 'https://pix6.agoda.net/geo/city/17190/1_17190_02.jpg?s=345x345&ar=1x1', title: 'Vũng Tàu', desc: '43 chỗ ở', url: ''},
        {image: 'https://pix6.agoda.net/geo/city/16440/1_16440_02.jpg?s=345x345&ar=1x1', title: 'Đà Lạt', desc: '28 chỗ ở', url: ''},
        {image: 'https://pix6.agoda.net/geo/city/13170/1_13170_02.jpg?s=345x345&ar=1x1', title: 'Hà Nội', desc: '87 chỗ ở', url: ''},
        {image: 'https://pix6.agoda.net/geo/city/15932/1_15932_02.jpg?s=345x345&ar=1x1', title: 'Nha Trang', desc: '547 chỗ ở', url: ''},
        {image: 'https://pix6.agoda.net/geo/city/2758/1_2758_02.jpg?s=345x345&ar=1x1', title: 'Phú Quốc', desc: '986 chỗ ở', url: ''},
        {image: 'https://pix6.agoda.net/geo/city/17190/1_17190_02.jpg?s=345x345&ar=1x1', title: 'Vũng Tàu', desc: '43 chỗ ở', url: ''},
        {image: 'https://pix6.agoda.net/geo/city/16440/1_16440_02.jpg?s=345x345&ar=1x1', title: 'Đà Lạt', desc: '28 chỗ ở', url: ''},
        {image: 'https://pix6.agoda.net/geo/city/13170/1_13170_02.jpg?s=345x345&ar=1x1', title: 'Hà Nội', desc: '87 chỗ ở', url: ''},
    ]);

    const [homeTypes, setHomeTypes] = useState([
        {image: 'https://cdn6.agoda.net/images/accommodation/other-property-types/entire-apartment.jpg', title: 'Hồ Chí Minh', desc: '930 chỗ ở', url: ''},
        {image: 'https://cdn6.agoda.net/images/accommodation/other-property-types/entire-house.jpg', title: 'Vũng Tàu', desc: '43 chỗ ở', url: ''},
        {image: 'https://cdn6.agoda.net/images/accommodation/other-property-types/entire-villa.jpg', title: 'Đà Lạt', desc: '28 chỗ ở', url: ''},
        {image: 'https://cdn6.agoda.net/images/accommodation/other-property-types/entire-bungalow.jpg', title: 'Hà Nội', desc: '87 chỗ ở', url: ''},
    ]);

    const [products, setProducts] = useState([1,2,3,4,5,6,7,8]);

    return (
        <div id="home-react-root">
            <div className="HomeReactContainer">
                <TopSection/>

                <BlockSection title="Các điểm đến thu hút nhất Việt Nam">
                    <ListCarousel data={citiesFeature} renderItem={(item: any) => <ImageCircle image={item}/>}/>
                </BlockSection>

                <BlockSection title="Khám phá thêm nhà cho thuê du lịch">
                    <ListGrid data={homeTypes} renderItem={(item: any) => <ImageSquare image={item}/>}/>
                </BlockSection>

                <BlockSection title="Những chỗ nghỉ nổi bật khuyến nghị cho bạn">
                    <Tabs defaultActiveKey="1" centered>
                        <TabPane tab="Hồ Chí Minh" key="1">
                            <ListProduct data={products} renderItem={(item: any) => <Product image={item}/>}/>
                        </TabPane>
                        <TabPane tab="Vũng Tàu" key="2">
                            <ListProduct data={products} renderItem={(item: any) => <Product image={item}/>}/>
                        </TabPane>
                        <TabPane tab="Đà Nẵng" key="3">
                            <ListProduct data={products} renderItem={(item: any) => <Product image={item}/>}/>
                        </TabPane>
                        <TabPane tab="Nha Trang" key="4">
                            <ListProduct data={products} renderItem={(item: any) => <Product image={item}/>}/>
                        </TabPane>
                        <TabPane tab="Hải Phòng" key="5">
                            <ListProduct data={products} renderItem={(item: any) => <Product image={item}/>}/>
                        </TabPane>
                    </Tabs>

                </BlockSection>
            </div>
        </div>
    )
}
