import React from "react";
import {Button} from "antd";

export default function Header(props: any) {
    return (
        <header id="page-header" className="navbar-agoda main-header main-header-ie9 scrollTo">
            <div>
                <section className="header-container">
                    <nav className="header-container__item header-container__item--left LogoAndLinksMenu">
                        <div className="LogoAndLinksMenu__LogoContainer">
                            <a href="/?cid=1844104" className="LogoAndLinksMenu__Link">
                                <img className="LogoAndLinksMenu__Logo"
                                     src="/logo.svg"/>
                            </a>
                        </div>
                        <ul className="LinkContainer">
                            <li className="LinkContainer__LinkWrapper">
                                <a href="" className="LinkContainer__Link">
                                    <span className="LinkContainer__Link__text">Phòng khách sạn</span>
                                </a>
                            </li>
                            <li className="LinkContainer__LinkWrapper">
                                <a href="" className="LinkContainer__Link">
                                    <span className="LinkContainer__Link__text">Vé máy bay</span>
                                </a>
                            </li>
                            <li className="LinkContainer__LinkWrapper">
                                <a href="" className="LinkContainer__Link">
                                    <span className="LinkContainer__Link__text">Tour du lịch</span>
                                </a>
                            </li>
                            <li className="LinkContainer__LinkWrapper">
                                <a href="" className="LinkContainer__Link">
                                    <span className="LinkContainer__Link__text">Tin tức</span>
                                </a>
                            </li>
                            <li className="LinkContainer__LinkWrapper">
                                <a href="" className="LinkContainer__Link">
                                    <span className="LinkContainer__Link__text">Tuyển dụng</span>
                                </a>
                            </li>
                        </ul>
                        <div className="EllipsisLinkContainer">
                            <i className="EllipsisLinkContainer__Button ficon ficon-21 ficon-show-more"/>
                        </div>
                    </nav>
                    <span className="header-container__space"/>
                    <nav className="header-container__item header-container__item--right">
                        <ul className="header-menu header-menu--reverse-order header-menu--master">
                            <li className="header-menu__group">
                                <ul className="header-menu__subgroup">
                                    <li className="header-menu__item">
                                        <Button type="link">Đăng nhập</Button>
                                    </li>
                                    <li className="header-menu__item">
                                        <Button size="large" type="primary" ghost>Tạo tài khoản</Button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </section>
            </div>
        </header>
    )
}
