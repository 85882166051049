import HttpService from "./http.service";
import Environment from "../environment";

export default class HotelService {
    static find(query: any) {
        return HttpService.post(Environment.host.concat('/hotel/find'), query);
    }

    static get(id: string) {
        return HttpService.get(Environment.host.concat(`/hotel/${id}`));
    }

}
