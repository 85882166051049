import {Rate} from "antd";
import React from "react";
import styled from "styled-components";
import {StarFilled} from '@ant-design/icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

const Container = styled.div`

`;
const ContainerTop = styled.div`
  max-width: 100%;
  padding: 2px;
`;
const ContainerContent = styled.div`
  box-sizing: border-box;
  margin: 0px;
  max-width: 100%;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 4px);
  overflow: hidden;
`;
const ContainerPrice = styled.div`
  max-width: 100%;
  padding: 2px;
`;
const ContainerName = styled.div`
  max-width: 100%;
  padding: 2px;
`;
const ContainerAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  word-wrap: break-word;
`;
const ContainerRate = styled.div``;
const Name = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: rgb(42, 42, 46);
`;
const Address = styled.span`
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  margin: 0 2px;
  word-wrap: break-word;
`;
const PriceLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: rgb(115, 115, 115);
  display: block;
`;
const Price = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: rgb(225, 45, 45);
  display: block;
`;
const Image = styled.img``;

export default function Product(props: any) {
    const history = useHistory();

    const onDetail = (id: string) => {
        history.push(`/hotel/detail/${id}`)
    }

    return (
        <Container>
            <a onClick={event => onDetail('x')}>
                <div>
                    <ContainerTop>
                        <Image
                            src="//q-xx.bstatic.com/xdata/images/hotel/max500/274009032.jpg?k=1e9b7eeda5d5db9c06ebf7b219fdcf87b69aa520ac43ffbe58edccbbe13a010c&amp;o="
                            width="100%" height="200px" className="FeaturedPropertyCard__Image"/>
                    </ContainerTop>
                    <ContainerName>
                        <Name>Holly's Hidden Nest in Prime Location</Name>
                    </ContainerName>
                    <ContainerContent>
                        <ContainerRate>
                            <Rate character={<StarFilled/>} defaultValue={4} allowHalf
                                  style={{fontSize: 11, margin: 0}}/>
                        </ContainerRate>
                        <ContainerAddress>
                            <FontAwesomeIcon icon={faMapMarkerAlt}/>
                            <Address>Bình Thạnh</Address>
                            <Address>Hồ Chí Minh</Address>
                        </ContainerAddress>
                    </ContainerContent>
                    <ContainerPrice>
                        <PriceLabel>Giá mỗi đêm rẻ nhất từ</PriceLabel>
                        <Price>100,320 VNĐ</Price>
                    </ContainerPrice>

                </div>
            </a>
        </Container>
    )
}
