import React, {useEffect, useRef, useState} from "react";
import {DatePicker} from 'antd';
import styled from "styled-components";
import "./style.scss";
import moment from "moment";
import locale from 'antd/es/date-picker/locale/vi_VN';
import 'moment/locale/vi';
moment.locale('vi');
const {RangePicker} = DatePicker;

const Container = styled.div`
  position: relative;
  width: 100%;
  flex: 1
`;
const ContainerPicker = styled.div`
  position: absolute;
  z-index: 2;
`;

export default function DateInput(props: any) {
    const [value, setValue]: any = useState([
        moment(new Date()).add(10, "days"),
        moment(new Date()).add(15, "days"),
    ]);
    const [dates, setDates]: any = useState([]);
    const [datePickerWidth, setDatePickerWidth]: any = useState(0);
    const refContainer: any = useRef();
    const refPicker: any = useRef();

    useEffect(() => {
        setDatePickerWidth(refContainer.current.offsetWidth);
    }, [refContainer]);

    return <Container className='box-search-date'>
        <ContainerPicker>
            <RangePicker
                locale={locale}
                defaultValue={value}
                size="large"
                style={{width: datePickerWidth * 2, opacity: 0}}
                ref={refPicker}
                value={value}
                onCalendarChange={val => setDates(val)}
                onChange={val => setValue(val)}
            />
        </ContainerPicker>

        <div style={{width: '100%', flex: 1, zIndex: 1}}>
            <div className="IconBox IconBox--checkIn" style={{width: '49%'}} ref={refContainer}>
                <div className="IconBox__wrapper">
                    <i className="ficon IconBox__icon ficon-20 ficon-check-in"/>
                    <div className="IconBox__child">
                        <div className="SearchBoxTextDescription SearchBoxTextDescription--checkIn">
                            <div className="SearchBoxTextDescription__title">
                                {moment(value[0].toDate()).format('dddd, DD/MM/YYYY')}
                            </div>
                            <div
                                className="SearchBoxTextDescription__desc">
                                Ngày bắt đầu
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="IconBox IconBox--checkOut" style={{width: '49%'}}>
                <div className="IconBox__wrapper">
                    <i className="ficon IconBox__icon ficon-20 ficon-check-out"/>
                    <div className="IconBox__child">
                        <div className="SearchBoxTextDescription SearchBoxTextDescription--checkOut">
                            <div className="SearchBoxTextDescription__title">
                                {moment(value[1].toDate()).format('dddd, DD/MM/YYYY')}
                            </div>
                            <div className="SearchBoxTextDescription__desc">
                                Ngày kết thúc
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}
