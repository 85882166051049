import React, {useEffect, useState} from "react";
import styled from "styled-components";
import AddressInput from "../../../constants/TopSection/components/AddressInput";
import DateInput from "../../../constants/TopSection/components/DateInput";
import PeopleInput from "../../../constants/TopSection/components/PeopleInput";
import "./style.scss";
import Breadcrumb from "../../../constants/Breadcrumb";
import ImagesView from "../../../components/ImagesView";
import {StarFilled} from "@ant-design/icons";
import {Rate} from "antd";
import Room from "../../../components/Room";
import {useHistory, useParams} from "react-router-dom";
import HotelService from "../../../services/hotel.service";
import {useSelector} from "react-redux";

const ContainerHead = styled.div`

`;
const ContainerBody = styled.div``;

const BoxHead = styled.div`
  border-radius: 4px;
  padding: 16px;
  box-shadow: none;
  margin: 16px 0px 0px;
  position: relative;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(221, 223, 226);
`;
const BoxHeadTitle = styled.h3`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  border-bottom: 1px solid rgb(221, 223, 226);
  margin-bottom: 15px;
  padding-bottom: 15px;
`;
const FeatureContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export default function HotelDetailPage(props: any) {
    const history = useHistory();
    const {hotelId}: any = useParams();
    const [hotel, setHotel]: any = useState();
    const language = useSelector((state: any) => state.App.language);

    useEffect(() => {
        HotelService.get(hotelId).then(value => {
            setHotel(value ? value[0] : null);
        });
    }, [hotelId])

    const breadcrumb = [
        'Trang chủ', 'Khách sạn Việt Nam', 'Khách sạn Hồ Chí Minh'
    ];


    const onOrder = () => {
        history.push(`/order`)
    }

    return hotel ? <div className='hotel-detail'>
        <ContainerHead>
            <div className="SearchBoxWrapper">
                <div className="SearchBoxContainer SearchBoxContainer--AllRooms SearchBoxContainer--compact"
                     id="SearchBoxContainer">
                    <div className="Searchbox Searchbox--horizontal">
                        <div className="Searchbox__wrapper detail-page">
                            <AddressInput/>
                            <DateInput/>
                            <PeopleInput/>
                            <button className="btn Searchbox__searchButton Searchbox__searchButton--active">
                                <span className="Searchbox__searchButton__text">Cập Nhật</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Breadcrumb breadcrumb={breadcrumb}/>
            <div className={'container-app'}>
                <ImagesView images={hotel.images.map((image: any) => image.url)}>
                    <div className={'price-view'}>
                        <p>Giá chỉ từ</p>
                        <h5>2.122.000đ</h5>
                    </div>
                </ImagesView>

                <BoxHead>
                    <div className="BadgeHolder">
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                            <g fill="none">
                                <path fill="#A5D6AB"
                                      d="M32.517 15.412c0 8.283-7.088 15-15.834 15-8.744 0-15.833-6.717-15.833-15 0-8.285 7.089-15 15.833-15 8.746 0 15.834 6.715 15.834 15"/>
                                <path fill="#0C8546"
                                      d="M32.88 20l-2.853 5.604-5.845 5.378-7.436 1.78-7.67-1.78-5.773-5.07L.563 20 16.746 3.768z"/>
                                <g fill="#FFF">
                                    <path
                                        d="M17.56 18.99h5.727v-.667a6.732 6.732 0 0 0-5.727-6.656v7.323zM15.727 18.99v-7.323A6.732 6.732 0 0 0 10 18.323v.667h5.727zM10 28.088h5.727v-7.096H10zM17.56 28.088h5.727v-7.096H17.56z"/>
                                </g>
                                <path fill="#0C8546"
                                      d="M16.746.08c9.205 0 16.667 7.461 16.667 16.666S25.95 33.413 16.746 33.413C7.541 33.413.079 25.95.079 16.746.08 7.541 7.541.079 16.746.079zm0 1.666c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"/>
                            </g>
                        </svg>
                        <span className="BadgeHolderText">Phòng khách sạn</span>
                    </div>
                    <div className="HeaderCerebrum">
                        <div className={'product-name'}>
                            <h1 className="HeaderCerebrum__Name">{hotel.name[language]}</h1>
                            <Rate character={<StarFilled/>} defaultValue={4} allowHalf
                                  style={{fontSize: 18, margin: 0}}/>
                        </div>
                        <div className="HeaderCerebrum__Location">
                                <span
                                    className="HeaderCerebrum__Address">{hotel.location.place[0].value.name}, {hotel.location.city[0].value.name}, {hotel.location.country[0].value.name}</span>
                            <span className="HeaderCerebrum__Map"> - <span className="HeaderCerebrum__SeeMap">TRÊN BẢN ĐỒ</span></span>
                        </div>
                    </div>
                </BoxHead>

                <BoxHead>
                    <BoxHeadTitle>Điểm nổi bật nhất về chỗ nghỉ</BoxHeadTitle>
                    <FeatureContainer>
                        {
                            hotel.features.map((feature: any, index: number) => (
                                <div key={feature._id} className={'feature-item-view'}>
                                    <img src={feature.value.icon}/>
                                    <div>
                                        <p>{feature.value?.name[language] || ''}</p>
                                        <span>{feature.value?.description?.[language] || ''}</span>
                                    </div>
                                </div>
                            ))
                        }

                    </FeatureContainer>
                </BoxHead>
            </div>


        </ContainerHead>
        <ContainerBody>
            <div className={'container-app'}>
                <div className="booking-body">
                    <div id="roomgrid-title">
                        <h3 className="kite-js-Typography ">Chọn phòng</h3>
                    </div>
                    <div className="sc-bdfBwQ iGXZWE">
                        <div className="PriceGuarantee">
                            <div role="button" className="sc-bdfBwQ iomxPg">
                                <i className="ficon ficon-20 ficon-agoda-price-guarante"/>
                                <a className="sc-laRPJI jcvOkR sc-bdfBwQ jVCnKv">
                                    <span className="sc-jSgupP Fpaqn">Chúng tôi khớp giá!</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    [1, 2, 3, 4, 5].map((value, index) => {
                        return (
                            <Room key={index.toString()} onSelect={(evt: any) => onOrder()}/>
                        )
                    })
                }
            </div>
        </ContainerBody>
    </div> : null
}
