import React from "react";
import styled from "styled-components";

const Desc = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: rgb(115, 115, 115);
  padding-top: 4px;
  display: block;
  text-align: center;
`

export default function ImageCircle(props: any) {
    return props.image ? (
        <div className="TopDestinationItem" style={{padding: '15px 0'}}>
            <a href="">
                <img className="TopDestinationItem__Image" src={props.image.image}/>
                <span className="TopDestinationItem__City">{props.image.title}</span>
                <Desc>{props.image.desc}</Desc>
            </a>
        </div>
    ) : null
}
