import React, {useState} from "react";
import "./style.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/thumbs/thumbs.min.css"
import SwiperCore, {Navigation, Thumbs} from 'swiper/core';

SwiperCore.use([Navigation, Thumbs]);

export default function ImagesView(props: any) {
    const images: Array<string> = props.images;
    const [thumbsSwiper, setThumbsSwiper]: any = useState(null);
    const [modalVisible, setModalVisible]: any = useState(false);

    return (<div className='images-view'>
        <div className='preview-images'>
            <div className='preview-images-main'>
                <img src={images[0] || ''}/>
            </div>
            <div className='preview-images-second'>
                <div className='preview-images-second-top'>
                    <img src={images[1] || ''}/>
                </div>
                <div className='preview-images-second-bottom'>
                    <div className='preview-images-second-bottom-left'>
                        <img src={images[2] || ""}/>
                    </div>
                    <div onClick={() => setModalVisible(true)} className='preview-images-second-bottom-right'>
                        <img src={images[3] || ''}/>
                        <p>Xem tất cả {images.length} hình ảnh</p>
                    </div>
                </div>
            </div>
            {props.children}
        </div>
        {
            modalVisible ? (
                <div className='preview-images-popup'>
                    <div className='preview-images-popup-container'>
                        <div onClick={() => {
                            setModalVisible(false);
                            setThumbsSwiper(null);
                        }} className="close-area"><span
                            className="close-area-icon ficon ficon-24 ficon-x-icon"/>
                        </div>
                        <Swiper slidesPerView={1} spaceBetween={10} navigation={true} thumbs={{swiper: thumbsSwiper}}
                                className="mySwiper2">
                            {images.map((value, index) => (
                                <SwiperSlide key={index}><img src={value}/></SwiperSlide>
                            ))}

                        </Swiper>
                        <Swiper onSwiper={setThumbsSwiper} spaceBetween={10} slidesPerView={4} className="mySwiper">
                            {images.map((value, index) => (
                                <SwiperSlide key={index}><img src={value}/></SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            ) : null
        }
    </div>)
}
