import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import HomePage from "../pages/Home";
import HotelDetailPage from "../pages/Hotel/Detail";
import OrderPage from "../pages/Order";
import OrderDonePage from "../pages/Order/done";
import HotelSearchPage from "../pages/Hotel/Search";

export default function AppRoute(props: any) {
    return <Router>
        <Switch>
            <Route path="/home">
                <HomePage/>
            </Route>
            <Route path="/hotel/search">
                <HotelSearchPage/>
            </Route>
            <Route path="/hotel/detail/:hotelId">
                <HotelDetailPage/>
            </Route>
            <Route path="/order/done">
                <OrderDonePage/>
            </Route>
            <Route path="/order">
                <OrderPage/>
            </Route>

            <Route path={'/'}>
                <Redirect to={'/home'}/>
            </Route>
        </Switch>
    </Router>
}
