import {Swiper, SwiperSlide} from 'swiper/react';
import React from "react";
import 'swiper/swiper.scss';
import styled from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  margin: -16px;
  width: calc(100% + 32px);
`;
const Item = styled.div`
  letter-spacing: 0;
  font-weight: 400;
  line-height: 1.42857;
  color: rgb(42, 42, 46);
  box-sizing: border-box;
  margin: 0px;
  flex-basis: 25%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 25%;
  padding: 16px;
`;

export default function ListGrid(props: any) {

    const renderItem = (item: any, index: number) => {
        return <Item key={index.toString()}>{props.renderItem(item)}</Item>
    }

    return (
        <Container>
            {
                props.data.map((item: any, index: number) => renderItem(item, index))
            }
        </Container>
    )
}
