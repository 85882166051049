import React, {useEffect, useRef, useState} from "react";
import {List, Popover} from "antd";
import styled from "styled-components";

const ContainerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;
const ContainerItemLeft = styled.div``;
const ContainerItemRight = styled.div``;
const Name = styled.h5`
  font-weight: 800;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #2a2a2e;
  margin-bottom: 6px;
`;
const Desc = styled.p`
  color: #6f6f6f;
  font-weight: 400;
  line-height: 18px;
  font-size: 14px;
  padding-left: 0px;
  margin-bottom: 0;
`;
const Type = styled.button`
  line-height: 18px;
  font-weight: 400;
  border: 1px solid;
  padding: 2px 4px;
  border-radius: 4px;
  min-width: 107px;
  text-align: center;
  font-size: 12px;
  color: #5392f9;
  border-color: #dde9fd;
  right: 20px;
  background-color: white;
`;

export default function AddressInput(props: any) {
    const [valueText, setValueText] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupWidth, setPopupWidth] = useState(0);
    const [data, setData] = useState([
        {type: 'Location', name: 'Hồ Chí Minh', description: 'Việt Nam'},
        {type: 'Location', name: 'Quận 1', description: 'Hồ Chí Minh'},
        {type: 'Location', name: 'Quận Tân Bình', description: 'Hồ Chí Minh'},
        {type: 'Location', name: 'Quận Bình Thạnh', description: 'Hồ Chí Minh'},
        {type: 'Location', name: 'Quận 2', description: 'Hồ Chí Minh'},
    ]);

    const boxRef: any = useRef();

    useEffect(() => {
        if (boxRef && boxRef.current) {
            setPopupWidth(boxRef.current.offsetWidth - 32);
        }
    }, [boxRef]);

    const onSelectItem = (item: any) => {
        setValueText(`${item.name}, ${item.description}`);
        setPopupVisible(false);
    }

    const renderItem = (item: any) => {
        return <ContainerItem onClick={() => onSelectItem(item)}>
            <ContainerItemLeft>
                <Name>{item.name}</Name>
                <Desc>{item.description}</Desc>
            </ContainerItemLeft>
            <ContainerItemRight>
                <Type>{'Khu vực'}</Type>
            </ContainerItemRight>
        </ContainerItem>
    }

    const renderPopup = () => {
        return <div style={{width: popupWidth}}>
            <List
                size="large"
                dataSource={data}
                renderItem={item => <List.Item style={{padding: 10}}>{renderItem(item)}</List.Item>}
            />
        </div>
    }

    return <div ref={boxRef} className="IconBox IconBox--autocomplete">
        <Popover placement="bottomLeft" content={() => renderPopup()} trigger="click" visible={popupVisible}
                 onVisibleChange={visible => setPopupVisible(visible)}>

            <div className="IconBox__wrapper">
                <i className="ficon IconBox__icon ficon-20 ficon-search-box"/>
                <div className="IconBox__child">
                    <input value={valueText} type="text" onChange={event => setValueText(event.target.value)}
                           className="SearchBoxTextEditor SearchBoxTextEditor--autocomplete"
                           placeholder="Nhập địa điểm bạn muốn đi.."/>
                </div>
            </div>
        </Popover>
    </div>
}
