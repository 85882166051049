import React from "react";
import styled from "styled-components";

const Title = styled.span`
  letter-spacing: 0;
  box-sizing: border-box;
  padding-bottom: 8px;
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: rgb(42, 42, 46);
  margin-bottom: 24px;
`

export default function BlockSection(props: any) {
    return (
        <div className="TopDestinationRail">
            <Title>{props.title}</Title>
            <div>
                {props.children}
            </div>
        </div>
    )
}
