import React from "react";
import styled from "styled-components";

const Desc = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgb(115, 115, 115);
`;
const Title = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: rgb(42, 42, 46);
`;
const ContainerInfo = styled.div`
  margin: 8px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
`;
const Container = styled.div`
  padding: 0px;
  position: relative;
  box-shadow: none;
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(221, 223, 226);
`;

export default function ImageSquare(props: any) {
    return props.image ? (
        <Container>
            <a href={props.url} target="_blank">
                <div className="property-type-card">
                    <img src={props.image.image} width="100%"/>
                    <ContainerInfo>
                        <Title>{props.image.title}</Title>
                        <Desc>{props.image.desc}</Desc>
                    </ContainerInfo>
                </div>
            </a>
        </Container>
    ) : null
}
