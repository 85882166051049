import React from 'react';
import AddressInput from "../../../constants/TopSection/components/AddressInput";
import DateInput from "../../../constants/TopSection/components/DateInput";
import PeopleInput from "../../../constants/TopSection/components/PeopleInput";
import Breadcrumb from "../../../constants/Breadcrumb";
import ImagesView from "../../../components/ImagesView";
import {Rate} from "antd";
import {StarFilled} from "@ant-design/icons";
import styled from "styled-components";

const ContainerHead = styled.div`
`;
const ContainerBody = styled.div`
`;

export default function HotelSearchPage(props: any) {
    return (
        <ContainerHead>
            <div className="SearchBoxWrapper">
                <div className="SearchBoxContainer SearchBoxContainer--AllRooms SearchBoxContainer--compact"
                     id="SearchBoxContainer">
                    <div className="Searchbox Searchbox--horizontal">
                        <div className="Searchbox__wrapper detail-page">
                            <AddressInput/>
                            <DateInput/>
                            <PeopleInput/>
                            <button className="btn Searchbox__searchButton Searchbox__searchButton--active">
                                <span className="Searchbox__searchButton__text">Cập Nhật</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="FilterBar" className="Filter hidePopularFilter">
                <div className="Filter__Container"><span className="Filter__Label">Lọc chỗ nghỉ theo</span>
                    <div className="PillDropdown">
                        <button className="btn PillDropdown__Button">
                            <div className="PillDropdown__Text">
                                <i className="ficon ficon-16 PillDropdown__Icon ficon-promotion-right"/>
                                <span>Giá</span>
                            </div>
                            <i className="ficon PillDropdown__BackIcon PillDropdown__BackIcon--arrow ficon-desktop-ic-black-down"/>
                        </button>
                    </div>
                    <div className="PillDropdown"
                         data-element-value="StarRating">
                        <button className="btn PillDropdown__Button">
                            <div className="PillDropdown__Text">
                                <i className="ficon ficon-16 PillDropdown__Icon ficon-hotel-star"/>
                                <span>Sao</span></div>
                            <i className="ficon PillDropdown__BackIcon PillDropdown__BackIcon--arrow ficon-desktop-ic-black-down"/>
                        </button>
                    </div>

                    <div className="TextSearchContainer" data-element-name="search-filter-text-search">
                        <div className="Autosuggest__Container">
                            <input type="text" autoComplete="off" className="Autosuggest__TextEditor form-control"
                                   value="" placeholder="Từ khóa hay tên khách sạn"/>
                            <div id="react-autowhatever-1" className="SuggestionList__Container"></div>
                        </div>
                        <i className="ficon ficon-search-box ficon-16 TextSearch__Icon"></i></div>
                </div>
                <div className="topbar-wrapper__overlay"></div>
            </div>


        </ContainerHead>
    )
}
