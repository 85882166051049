import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from "styled-components";

const Item = styled.li`
  margin-right: 8px;
  margin-left: 8px;
  padding-top: 16px;
  width: 140px;
  position: relative;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
`;
const Title = styled.h6`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: 0px;
  padding-top: 8px;
  padding-bottom: 12px;
  position: relative;
  -webkit-box-flex: 1;
  flex-grow: 1;
  color: rgb(42, 42, 46);
`;

export default function TabItem(props: any) {
    const active = props.active;
    const onPress = props.onPress;

    return (
        <Item onClick={onPress}
              className={`sc-hKgILt clMvWY LinkComponent__Container ${active ? 'tab-selection-active' : ''}`}>
            <FontAwesomeIcon size='2x' icon={props.icon}/>
            <div className="sc-hKgILt eJYWHN">
                <Title>{props.title}</Title>
            </div>
        </Item>
    )
}
