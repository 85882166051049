import React from 'react';
import "./style.scss";

export default function Footer(props: any) {
    return (
        <footer className="footer mt-4">
            <div className="space-bottom-2 space-top-1">
                <div className="TopDestinationRail" style={{marginTop: 12, marginBottom: 12}}>
                    <div className="row justify-content-xl-between">
                        <div className="col-md-3">
                            <div className="d-md-flex d-lg-block">
                                <div>
                                    <h4 className="h6 mb-4 font-weight-bold">Need My Travel Help?</h4>
                                    <a href="tel:(888)-1234-56789">
                                        <div className="d-flex align-items-center">
                                            <div className="mr-4">

                                            </div>
                                            <div>
                                                <div className="mb-2 h6 font-weight-normal text-gray-1">Got Questions ?
                                                    Call us 24/7!
                                                </div>
                                                <small className="d-block font-size-18 font-weight-normal text-primary">Call
                                                    Us: <span className="text-primary font-weight-semi-bold">(888) 1234 56789</span></small>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <div className="mb-4">
                                        <h4 className="h6 font-weight-bold mb-2 font-size-17">Contact Info</h4>
                                        <address className="pr-4">
                                            <span className="mb-2 h6 font-weight-normal text-gray-1">
                                                PO Box CT16122 Collins Street West, Victoria 8007,Australia.
                                            </span>
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <h4 className="h6 font-weight-bold mb-2 mb-xl-4">Company</h4>
                            <ul className="list-group list-group-flush list-group-borderless mb-0">
                                <li><a className="text-decoration-on-hover list-group-item list-group-item-action"
                                       href="../others/about.html">About us</a></li>
                                <li><a className="text-decoration-on-hover list-group-item list-group-item-action"
                                       href="../others/contact.html">Careers</a></li>
                                <li><a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/terms-conditions.html">Terms of Use</a></li>
                                <li><a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/terms-conditions.html">Privacy Statement</a></li>
                                <li><a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/terms-conditions.html">Give Us Feedbacks</a></li>
                            </ul>
                        </div>

                        <div className="col-md-2">
                            <h4 className="h6 font-weight-bold mb-2 mb-xl-4">Other Services</h4>
                            <ul className="list-group list-group-flush list-group-borderless mb-0">
                                <li><a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/about.html">Investor Relations</a></li>
                                <li><a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/about.html">Rewards Program</a></li>
                                <li><a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/about.html">PointsPLUS</a></li>
                                <li><a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/about.html">Partners</a></li>
                                <li><a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/about.html">List My Hotel</a></li>
                            </ul>
                        </div>

                        <div className="col-md-2">
                            <h4 className="h6 font-weight-bold mb-2 mb-xl-4">Support</h4>
                            <ul className="list-group list-group-flush list-group-borderless mb-0">
                                <li>
                                    <a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/terms-conditions.html">Account</a>
                                </li>
                                <li>
                                    <a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/terms-conditions.html">Legal</a>
                                </li>
                                <li>
                                    <a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/contact.html">Contact</a>
                                </li>
                                <li>
                                    <a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/terms-conditions.html">Affiliate Program</a>
                                </li>
                                <li>
                                    <a className="list-group-item list-group-item-action text-decoration-on-hover"
                                       href="../others/terms-conditions.html">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4 mb-xl-2">
                                <h4 className="h6 font-weight-bold mb-2 mb-xl-4">Mailing List</h4>
                                <p className="m-0 text-gray-1">Sign up for our mailing list to get latest updates and
                                    offers.</p>
                            </div>
                            <form className="js-validate js-focus-state js-form-message">
                                <label className="sr-only text-gray-1" htmlFor="subscribeSrEmailExample1">Your
                                    Email</label>
                                <div className="input-group">
                                    <input type="email"
                                           className="form-control height-54 font-size-14 border-radius-3 border-width-2 border-color-8"
                                           name="email" id="subscribeSrEmailExample1" placeholder="Your Email"
                                           aria-label="Your email address" aria-describedby="subscribeButtonExample3"
                                           data-msg="Please enter a valid email address."
                                           data-error-class="u-has-error" data-success-class="u-has-success"/>
                                    <div className="input-group-append ml-3">
                                        <button
                                            className="btn btn-sea-green border-radius-3 height-54 min-width-112 font-size-14"
                                            type="submit" id="subscribeButtonExample3">Subscribes
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-1">
                <div className="TopDestinationRail" style={{margin: '0px auto'}}>
                    <div className="">
                        <p>© 2021 TrippyAsia. All rights reserved <a
                            className="list-group-item-action text-decoration-on-hover pr-3" href="../home/index.html">Designed
                            by Nginx</a></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
