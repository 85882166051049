import React, {useEffect, useRef, useState} from "react";
import {List, Popover} from "antd";
import styled from "styled-components";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 0;
`;
const Title = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: black;
`;
const ControlRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
`;
const Counter = styled.b`
  font-size: 16px;
  font-weight: 400;
`;

export default function PeopleInput(props: any) {
    const [adult, setAdult] = useState(2);
    const [children, setChildren] = useState(0);
    const [room, setRoom] = useState(1);
    const [popupVisible, setPopupVisible] = useState(false);


    const updateSize = (field: string, next: number) => {
        switch (field) {
            case 'adult': {
                if (adult + next >= 0 && adult + next <= 100) {
                    setAdult(adult + next);
                }
                break;
            }
            case 'children': {
                if (children + next >= 0 && children + next <= 100) {
                    setChildren(children + next);
                }
                break;
            }
            case 'room': {
                if (room + next >= 0 && room + next <= 100) {
                    setRoom(room + next);
                }
                break;
            }
        }
    }


    const renderPopup = () => {
        return <div style={{width: 200}}>
            <InputRow>
                <Title>Người lớn</Title>
                <ControlRow>
                    <MinusCircleOutlined onClick={() => updateSize('adult', -1)}
                                         style={{fontSize: 20, color: 'rgb(83,146,249)', cursor: "pointer"}}/>
                    <Counter>{adult}</Counter>
                    <PlusCircleOutlined onClick={() => updateSize('adult', 1)}
                                        style={{fontSize: 20, color: 'rgb(83,146,249)', cursor: "pointer"}}/>
                </ControlRow>
            </InputRow>
            <InputRow>
                <Title>Trẻ em</Title>
                <ControlRow>
                    <MinusCircleOutlined onClick={() => updateSize('children', -1)}
                                         style={{fontSize: 20, color: 'rgb(83,146,249)', cursor: "pointer"}}/>
                    <Counter>{children}</Counter>
                    <PlusCircleOutlined onClick={() => updateSize('children', 1)}
                                        style={{fontSize: 20, color: 'rgb(83,146,249)', cursor: "pointer"}}/>
                </ControlRow>
            </InputRow>
            <InputRow>
                <Title>Phòng</Title>
                <ControlRow>
                    <MinusCircleOutlined onClick={() => updateSize('room', -1)}
                                         style={{fontSize: 20, color: 'rgb(83,146,249)', cursor: "pointer"}}/>
                    <Counter>{room}</Counter>
                    <PlusCircleOutlined onClick={() => updateSize('room', 1)}
                                        style={{fontSize: 20, color: 'rgb(83,146,249)', cursor: "pointer"}}/>
                </ControlRow>
            </InputRow>
        </div>
    }

    return (<div className="IconBox IconBox--occupancy">
        <Popover placement="bottomLeft" content={() => renderPopup()} trigger="click" visible={popupVisible}
                 onVisibleChange={visible => setPopupVisible(visible)}>
            <div className="IconBox__wrapper">
                <i className="ficon IconBox__icon ficon-20 ficon-travelers"/>
                <div className="IconBox__child">
                    <div
                        className="SearchBoxTextDescription SearchBoxTextDescription--occupancy">
                        <div className="SearchBoxTextDescription__title">
                            <span>{adult} người lớn + {children} trẻ em</span>
                        </div>
                        <div className="SearchBoxTextDescription__desc">
                            {room} phòng
                        </div>
                        <div
                            className="SearchBoxTextDescription__child">
                            <i className="ficon ficon ficon-12 ficon-thin-arrow-down"/>
                        </div>
                    </div>
                </div>
            </div>
        </Popover>
    </div>)
}
