import React from 'react';
import { Result, Button } from 'antd';

export default function OrderDonePage(props: any) {
    return (
        <div>
            <Result
                status="success"
                title="Đơn đặt phòng thành công"
                subTitle="Mã đặt phòng: 2017182818828182881 Chúng tôi sẽ gửi cho bạn hóa đơn trong vài phút. Vui lòng đợi."
                extra={[
                    <Button type="primary" key="console">
                        Về trang chủ
                    </Button>,
                    <Button key="buy">Đặt thêm phòng</Button>,
                ]}
            />
        </div>
    );
}
