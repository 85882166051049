import React from 'react';
import './App.css';
import {Provider} from "react-redux";
import storeRedux from "./redux/store.redux";
import AppRoute from "./routers";
import 'antd/dist/antd.css';
import './assets/css/theme-trippyasia.css';
import './assets/css/home.css';
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
    return (
        <div className="app">
            <Provider store={storeRedux}>
                <Header/>
                <AppRoute/>
                <Footer/>
            </Provider>
        </div>

    );
}

export default App;
