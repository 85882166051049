import {combineReducers} from "redux";
import Auth from "./reducers/auth.reducer";

const defaultState = {
    language: 'vi'
}
const App = (state: any = defaultState, action: any) => {
    switch (action.type) {
        default: {
            return state;
        }
    }
}

export default combineReducers({
    App, Auth
});
