import React, {useState} from "react";
import {Background, SearchPanel, Submit, TabPanel} from "./components/Elements";
import TabItem from "./components/TabItem";
import {faHotel, faFighterJet, faBinoculars} from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import AddressInput from "./components/AddressInput";
import DateInput from "./components/DateInput";
import styled from "styled-components";
import PeopleInput from "./components/PeopleInput";

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export default function TopSection(props: any) {
    const [tab, setTab] = useState(1);

    return (
        <section className="TileContainer TileContainer--full">
            <Background/>
            <section className="TileContainer__column">
                <div className="TileContainer__wrapper">
                    <div className="WelcomeMessage">
                        <div className="WelcomeMessage__wrapper">
                            <h1>ĐẶT PHÒNG KHÁCH SẠN, VÉ MÁY BAY & TOUR DU LỊCH</h1>
                            <h2 className="WelcomeMessage__H2">
                                Nhận giá tốt nhất trên hơn 2.000.000 chỗ nghỉ trên toàn thế giới
                            </h2>
                        </div>
                    </div>
                    <div className="sc-hKgILt gMbCeD">
                        <TabPanel>
                            <TabItem onPress={() => (setTab(1))} active={tab === 1} icon={faHotel} title="Khách sạn"/>
                            <TabItem onPress={() => (setTab(2))} active={tab === 2} icon={faFighterJet}
                                     title="Vé máy bay"/>
                            <TabItem onPress={() => (setTab(3))} active={tab === 3} icon={faBinoculars}
                                     title="Tour du lịch"/>
                        </TabPanel>
                        <div>
                            <div
                                className="SearchBoxContainer SearchBoxContainer--AllRooms SearchBoxContainer--vertical SearchBoxContainer--noanimation SearchBoxContainer--HomePageNewDesign"
                                id="SearchBoxContainer">
                                <SearchPanel className='TabContent'>
                                    {
                                        tab == 1 ? (
                                            <div className="Searchbox Searchbox--vertical">
                                                <div className="Searchbox__wrapper">
                                                    <AddressInput/>
                                                    <FlexBox>
                                                        <DateInput/>
                                                        <PeopleInput/>
                                                    </FlexBox>
                                                    <div className="Popup__container Popup__container--garage-door">
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <p>Chưa hỗ trợ...</p>
                                        )
                                    }
                                </SearchPanel>

                                <div className="TabContent__Search--button">
                                    <Submit>Tìm kiếm ngay</Submit>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}
